






















































import Vue from 'vue'
import moment from 'moment'
import ReportForm from '@/components/ReportForm.vue'
import Organization from '../../../../store/models/Organization'
import api, { ApiResponse } from '../../../../api'
import zones from '../../../../api/zones.json'
import { mapGetters } from 'vuex'

export default Vue.extend({
  components: {
    ReportForm
  },

  data() {
    return {
      activeTab: 'disbursements',
      tabs: [
        { key: 'disbursements', text: 'Décaissements' },
        { key: 'refunds', text: 'Remboursements' },
        { key: 'global', text: 'Globaux' }
      ],
      exportFormat: 'xlsx',
      reportLoaders: {
        disbursements: {},
        refunds: {},
        global: {}
      },
      showSuccessAlert: false
    }
  },

  computed: {
    ...mapGetters('product', ['getCurrentProduct']),

    organizations(): Organization[] {
      return Organization.query()
        .where('type', 'sfd')
        .orderBy((sfd: any) => sfd.name.trim().toLowerCase())
        .get()
    },

    reports(this: any) {
      return [
        {
          tab: 'disbursements',
          title: 'Décaissements des SFD sur une période donnée',
          type: 'sfd_disbursement',
          filename: 'rapport_ligne_credit_sfd.csv',
          fields: [
            {
              type: 'date',
              name: 'dateFrom',
              label: 'Date de début',
              required: true
            },
            {
              type: 'date',
              name: 'dateTo',
              label: 'Date de fin',
              required: true
            },
            {
              type: 'choice',
              name: 'organizationId',
              label: 'SFD',
              itemText: 'name',
              itemValue: 'id',
              items: [
                { name: 'Tous les SFDs', value: '' },
                ...this.organizations
              ]
            }
          ]
        },
        {
          tab: 'disbursements',
          title:
            'Décaissements par département et commune sur une période donnée',
          type: 'state_and_common_disbursement',
          filename: 'rapport_credit_beneficiaires.csv',
          fields: [
            {
              type: 'date',
              name: 'dateFrom',
              label: 'Date de début',
              required: true
            },
            {
              type: 'date',
              name: 'dateTo',
              label: 'Date de fin',
              required: true
            },
            {
              type: 'choice',
              name: 'organizationId',
              label: 'SFD',
              itemText: 'name',
              itemValue: 'id',
              items: [
                { name: 'Tous les SFDs', value: '' },
                ...this.organizations
              ]
            }
          ]
        },
        {
          tab: 'disbursements',
          title: "Décaissements par secteur d'activités sur une période donnée",
          type: 'activity_sector_disbursement',
          filename: 'rapport_credit_beneficiaires.csv',
          fields: [
            {
              type: 'date',
              name: 'dateFrom',
              label: 'Date de début',
              required: true
            },
            {
              type: 'date',
              name: 'dateTo',
              label: 'Date de fin',
              required: true
            }
          ]
        },
        {
          tab: 'disbursements',
          title: 'Mise en place des crédits pour les bénéficiaires',
          type: 'beneficiary_dispatching',
          filename: 'rapport_credit_beneficiaires.csv',
          fields: [
            {
              type: 'choice',
              name: 'organizationId',
              label: 'SFD',
              itemText: 'name',
              itemValue: 'id',
              items: [
                { name: 'Tous les SFDs', value: '' },
                ...this.organizations
              ]
            },
            {
              type: 'choice',
              name: 'otm',
              label: 'OTM',
              items: [
                { text: 'Tous les OTM', value: '' },
                { text: 'MTN', value: 'mtn' },
                { text: 'MOOV', value: 'moov' }
              ]
            },
            {
              type: 'choice',
              name: 'state',
              label: 'Statut',
              items: [
                { text: 'Tous les statuts', value: '' },
                { text: 'Succès', value: 'paid' },
                { text: 'Échec', value: 'failed' },
                { text: 'En attente', value: 'pending' }
              ]
            },
            {
              type: 'date',
              name: 'dateFrom',
              label: 'Date de début',
              required: true
            },
            {
              type: 'date',
              name: 'dateTo',
              label: 'Date de fin',
              required: true
            }
          ]
        },
        {
          tab: 'refunds',
          title: 'Point des remboursements des SFD vers le FNM',
          type: 'credit_line_deadlines',
          filename: 'rapport_remboursements_sfd_to_fnm.csv',
          fields: [
            {
              type: 'date',
              name: 'dateFrom',
              label: 'Date de début',
              required: true
            },
            {
              type: 'date',
              name: 'dateTo',
              label: 'Date de fin',
              required: true
            },
            {
              type: 'choice',
              name: 'organizationId',
              label: 'SFD',
              itemText: 'name',
              itemValue: 'id',
              items: [
                { name: 'Tous les SFDs', value: '' },
                ...this.organizations
              ]
            },
            {
              type: 'choice',
              name: 'state',
              label: 'Statut',
              items: [
                { text: 'Tous les statuts', value: '' },
                { text: 'Succès', value: 'paid' },
                { text: 'Échec', value: 'failed' },
                { text: 'En attente', value: 'pending' }
              ]
            }
          ]
        },
        {
          tab: 'refunds',
          title:
            'Point des remboursements des groupes de bénéficiaires sur une période donnée',
          type: 'beneficiary_group_reimbursement',
          filename: 'rapport_remboursements_beneficiaires.csv',
          fields: [
            {
              type: 'date',
              name: 'dateFrom',
              label: 'Date de début',
              required: true
            },
            {
              type: 'date',
              name: 'dateTo',
              label: 'Date de fin',
              required: true
            },
            {
              type: 'choice',
              name: 'organizationId',
              label: 'SFD',
              itemText: 'name',
              itemValue: 'id',
              items: [
                { name: 'Tous les SFDs', value: '' },
                ...this.organizations
              ]
            },
            // {
            //   type: 'choice',
            //   name: 'department',
            //   label: 'Département',
            //   class: 'capitalize',
            //   reset: ['common'],
            //   items: [
            //     { text: 'Tous les départements', value: '' },
            //     ...zones.map(d => ({ text: d.name, value: d.name }))
            //   ]
            // },
            // {
            //   type: 'choice',
            //   name: 'common',
            //   label: 'Commune',
            //   class: 'capitalize',
            //   items({ department }: { department: string }) {
            //     if (department) {
            //       return [
            //         { text: 'Toutes les communes', value: '' },
            //         ...(
            //           zones.find(d => d.name === department)?.communes || []
            //         ).map(c => ({ text: c.name, value: c.name }))
            //       ]
            //     }
            //     return [{ text: 'Toutes les communes', value: '' }]
            //   }
            // },
            // {
            //   type: 'choice',
            //   name: 'otm',
            //   label: 'OTM',
            //   items: [
            //     { text: 'Tous les OTM', value: '' },
            //     { text: 'MTN', value: 'mtn' },
            //     { text: 'MOOV', value: 'moov' }
            //   ]
            // },
            {
              type: 'choice',
              name: 'state',
              label: 'Statut',
              items: [
                { text: 'Tous les statuts', value: '' },
                { text: 'Succès', value: 'paid' },
                { text: 'Échec', value: 'failed' },
                { text: 'En attente', value: 'pending' }
              ]
            }
          ]
        },
        {
          tab: 'refunds',
          title:
            'Point de la balance âgée des remboursements des sfd vers le fnm',
          type: 'sfd_to_fnm_reimbursement_aged_balance',
          filename: 'rapport_impayes_beneficiaires.csv',
          fields: [
            {
              type: 'choice',
              name: 'organizationId',
              label: 'SFD',
              itemText: 'name',
              itemValue: 'id',
              items: [
                { name: 'Tous les SFDs', value: '' },
                ...this.organizations
              ]
            },
            {
              type: 'date',
              name: 'dateFrom',
              label: 'Date de début',
              required: false,
              class: 'hidden'
            },
            {
              type: 'date',
              name: 'dateTo',
              label: 'Date de fin',
              required: false,
              class: 'hidden'
            }
          ]
        },
        {
          tab: 'refunds',
          title:
            'Point de la balance âgée des remboursements des bénéficiaires vers les sfd',
          type: 'beneficiary_to_sfd_reimbursement_aged_balance',
          filename: 'rapport_remboursement_sfd.csv',
          fields: [
            {
              type: 'choice',
              name: 'organizationId',
              label: 'SFD',
              itemText: 'name',
              itemValue: 'id',
              items: [
                { name: 'Tous les SFDs', value: '' },
                ...this.organizations
              ]
            },
            {
              type: 'date',
              name: 'dateFrom',
              label: 'Date de début',
              required: false,
              class: 'hidden'
            },
            {
              type: 'date',
              name: 'dateTo',
              label: 'Date de fin',
              required: false,
              class: 'hidden'
            }
          ]
        },
        {
          tab: 'refunds',
          title: 'Point du tableau de bord',
          type: 'dashboard',
          filename: 'rapport_impayes_sfd.csv',
          fields: [
            {
              type: 'choice',
              name: 'organizationId',
              label: 'SFD',
              itemText: 'name',
              itemValue: 'id',
              items: [
                { name: 'Tous les SFDs', value: '' },
                ...this.organizations
              ]
            },
            {
              type: 'date',
              name: 'dateFrom',
              label: 'Date de début',
              required: false,
              class: 'hidden'
            },
            {
              type: 'date',
              name: 'dateTo',
              label: 'Date de fin',
              required: false,
              class: 'hidden'
            }
          ]
        },
        {
          tab: 'refunds',
          title: 'Point du suivi mensuel des remboursements',
          type: 'monthly_reimbursement',
          filename: 'rapport_impayes_sfd.csv',
          fields: [
            {
              type: 'choice',
              name: 'organizationId',
              label: 'SFD',
              itemText: 'name',
              itemValue: 'id',
              items: [
                { name: 'Tous les SFDs', value: '' },
                ...this.organizations
              ]
            },
            {
              type: 'date',
              name: 'dateFrom',
              label: 'Date de début',
              required: false,
              class: 'hidden'
            },
            {
              type: 'date',
              name: 'dateTo',
              label: 'Date de fin',
              required: false,
              class: 'hidden'
            }
          ]
        },
        {
          tab: 'refunds',
          title: 'Reporting sur les impayés',
          type: 'unpaid',
          filename: 'rapport_impayes.csv',
          fields: [
            {
              type: 'choice',
              name: 'organizationId',
              label: 'SFD',
              itemText: 'name',
              itemValue: 'id',
              items: [
                { name: 'Tous les SFDs', value: '' },
                ...this.organizations
              ]
            },
            {
              type: 'date',
              name: 'dateFrom',
              label: 'Date de début',
              required: true
            },
            {
              type: 'date',
              name: 'dateTo',
              label: 'Date de fin',
              required: true
            }
          ]
        },
        {
          tab: 'refunds',
          title: 'Reporting global',
          type: 'global',
          filename: 'rapport_global.csv',
          fields: [
            {
              type: 'choice',
              name: 'organizationId',
              label: 'SFD',
              itemText: 'name',
              itemValue: 'id',
              items: [
                { name: 'Tous les SFDs', value: '' },
                ...this.organizations
              ]
            },
            {
              type: 'date',
              name: 'dateFrom',
              label: 'Date de début',
              required: true
            },
            {
              type: 'date',
              name: 'dateTo',
              label: 'Date de fin',
              required: true
            }
          ]
        },
        {
          tab: 'global',
          title: 'Reporting global par groupe de bénéficiaire',
          type: 'global_by_beneficiary_group',
          filename: 'global_by_beneficiary_group.csv',
          fields: [
            {
              type: 'choice',
              name: 'organizationId',
              label: 'SFD',
              itemText: 'name',
              itemValue: 'id',
              items: [
                { name: 'Tous les SFDs', value: '' },
                ...this.organizations
              ]
            },
            {
              type: 'date',
              name: 'dateFrom',
              label: 'Date de début',
              required: true
            },
            {
              type: 'date',
              name: 'dateTo',
              label: 'Date de fin',
              required: true
            }
          ]
        },
        {
          tab: 'global',
          title: 'Reporting global par bénéficiaire',
          type: 'global_by_beneficiary',
          filename: 'global_by_beneficiary.csv',
          fields: [
            {
              type: 'choice',
              name: 'organizationId',
              label: 'SFD',
              itemText: 'name',
              itemValue: 'id',
              items: [
                { name: 'Tous les SFDs', value: '' },
                ...this.organizations
              ]
            },
            {
              type: 'date',
              name: 'dateFrom',
              label: 'Date de début',
              required: true
            },
            {
              type: 'date',
              name: 'dateTo',
              label: 'Date de fin',
              required: true
            }
          ]
        }
      ]
    },

    displayedReports(this: any) {
      return this.reports.filter((report: any) => report.tab === this.activeTab)
    }
  },

  created() {
    return Organization.api().fetch()
  },

  methods: {
    onGenerateReportClicked(
      this: any,
      options: Record<string, unknown>,
      report: any,
      index: number
    ) {
      Object.keys(options).forEach(key => {
        if (options[key] instanceof Date) {
          options[key] = moment(options[key] as Date).format('YYYY-MM-DD')
        }
      })

      //TODO Remove after recette
      if (
        ['balance_agee_sfd_to_fnm', 'balance_agee_beneficiary_to_sfd'].includes(
          report.type
        )
      )
        return
      this.$set(this.reportLoaders[this.activeTab], index, true)

      const { organizationId, dateFrom, dateTo, sendMail, ...filters } = options

      api
        .makeRequest({
          method: 'POST',
          url: 'reports',
          data: {
            type: report.type,
            title: report.title,
            format: this.exportFormat,
            productId: this.getCurrentProduct.id,
            dateFrom,
            dateTo,
            organizationId,
            sendMail: true,
            filters
          }
        })
        .then((response: ApiResponse) => {
          this.showSuccessAlert = true
          this.checkReportStatus(response.data, report.title, index)
        })
    },

    checkReportStatus(this: any, report: any, title: string, index: number) {
      api
        .makeRequest({
          method: 'GET',
          url: `/reports/${report.id}`
        })
        .then((response: ApiResponse) => {
          if (response.isSuccessful) {
            // if (!['completed', 'failed'].includes(response.data.state)) {
            //   return setTimeout(() => {
            //     this.checkReportStatus(report, title, index)
            //   }, 5000)
            // }
            this.$set(this.reportLoaders[this.activeTab], index, false)

            if (response.data.state === 'completed') {
              api
                .download(
                  `/reports/${report.id}/download`,
                  {},
                  title.toUpperCase().replace(/ /g, '_') +
                    '_DU_' +
                    moment(report.dateFrom).format('DD_MM_YYYY') +
                    '_AU_' +
                    moment(report.dateTo).format('DD_MM_YYYY') +
                    '.' +
                    response.data.format
                )
                .then(() => {
                  this.$set(this.reportLoaders[this.activeTab], index, false)
                })
            } else if (response.data.state === 'failed') {
              this.$set(this.reportLoaders[this.activeTab], index, false)
            }
          }
        })
    }
  }
})
