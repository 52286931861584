



























































































import Vue from 'vue'

export default Vue.extend({
  props: {
    title: {
      type: String,
      required: true
    },

    fields: {
      type: Array,
      required: true
    },

    loading: Boolean
  },

  data() {
    return {
      opened: false,
      sendMail: true,
      model: this.fields.reduce((model: any, field: any) => {
        if (field.type === 'date') {
          model[field.name] = new Date()
        } else {
          model[field.name] = ''
        }
        return model
      }, {})
    }
  },

  methods: {
    onFieldValueChanged(
      this: any,
      name: string,
      value: unknown,
      reset?: string[]
    ) {
      this.$set(this.model, name, value)
      if (reset) {
        reset.forEach(field => {
          this.$set(this.model, field, '')
        })
      }
    },

    onSubmit() {
      this.$emit(
        'submit',
        Object.assign(this.model, { sendMail: this.sendMail })
      )
    }
  }
})
